import { createContext, useContext, useState } from "react";

const GlobalContext = createContext({
  isOnWhitelist: false,
  setIsOnWhitelist: () => {},
  hasDRx: false,
  setHasDRx: () => {},
  hasSeekers: false,
  setHasSeekers: () => {},
  setIsOnWhitelist: () => {},
  saleStatusHooks: {},
  setSaleStatusHooks: () => {},
  salt: null,
  setSalt: () => {},
  transactionProcessing: false,
  setTransactionProcessing: () => {},
  tx: "",
  setTx: () => {},
});

const GlobalProvider = ({ children }) => {
  const [isOnWhitelist, setIsOnWhitelist] = useState(false);
  const [hasDRx, setHasDRx] = useState(false);
  const [hasSeekers, setHasSeekers] = useState(false);
  const [hasMintedWhitelist, setHasMintedWhitelist] = useState(false);
  const [salt, setSalt] = useState({});
  const [saleStatusHooks, setSaleStatusHooks] = useState({});
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [tx, setTx] = useState("");

  const value = {
    saleStatusHooks,
    setSaleStatusHooks,
    isOnWhitelist,
    setIsOnWhitelist,
    hasDRx,
    setHasDRx,
    hasSeekers,
    setHasSeekers,
    hasMintedWhitelist,
    setHasMintedWhitelist,
    setSalt,
    salt,
    transactionProcessing,
    setTransactionProcessing,
    tx,
    setTx,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be within a GlobalContext Provider");
  }
  return context;
};

export { GlobalProvider, useGlobalContext };
