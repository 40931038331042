import { ethers } from "ethers";
import abi from "../abis/abi.json";
import fetch from "node-fetch";

const PRICE_PER_NFT = ethers.utils.bigNumberify("80000000000000000");
const MAX_NFTS_PER_WALLET = 5;

// use deployed API URL
const API_URL = "https://3d-wearable-mint-api.srv.fluf.app/";
// const API_URL = "http://localhost:3001";

// TODO: add contract address once deployed and use DRx and Seeker addresses
const CONTRACT_ADDRESS = "0x03Ade60cE17FDefaCD5A28b1059aFC5760397D3B"; 
const DRx_CONTRACT_ADDRESS = "0x00Ea2894FE840F105ab99A8f8F75B1F17e94843A"; 
const SEEKERS_CONTRACT_ADDRESS = "0xaAF03a65CbD8f01b512Cd8d530a675b3963dE255"; 

// test & dummy contracts deployed on rinkeby
// const CONTRACT_ADDRESS = "0x829E06c72C561c5C023eee1ddb65c70e65788522"; 
// const DRx_CONTRACT_ADDRESS = "0x71422ceE66D1B561aE7de01622E481810092Cb5e"; 
// const SEEKERS_CONTRACT_ADDRESS = "0x2848F908cC4b486632239Fc64cBA9c39AB9bc7D5";


const getHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const extractBody = async (res) => {
  if (!res.ok) {
    throw new Error(await res.text());
  } else {
    return res.json();
  }
};

let provider, signer, contractProvider, contractSigner;
let drxContractProvider, seekersContractProvider;

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
  drxContractProvider = new ethers.Contract(DRx_CONTRACT_ADDRESS, abi, provider);
  seekersContractProvider = new ethers.Contract(SEEKERS_CONTRACT_ADDRESS, abi, provider);

} else {
  // INFURA FALLBACK RPC HERE
  let fallbackProvider = ethers.getDefaultProvider("homestead", {});
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, fallbackProvider);
  drxContractProvider = new ethers.Contract(DRx_CONTRACT_ADDRESS, abi, fallbackProvider);
  seekersContractProvider = new ethers.Contract(SEEKERS_CONTRACT_ADDRESS, abi, fallbackProvider);
}

export const updateProviderAndSigner = (library) => {
  if (library) {
    provider = new ethers.providers.Web3Provider(library.provider);
  } else {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  }
  signer = provider.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
};

export const getTotalSupply = async () => {
  if (contractProvider) {
    let supply = await contractProvider.totalSupply();
    return supply.toLocaleString("en-US");
  } else {
    return "?";
  }
};

export const retrieveWalletHasDRx = async (walletAddress) => {
  let balance = await drxContractProvider.balanceOf(walletAddress);
  return { hasDRx: balance > 0 }
};

export const retrieveWalletHasSeekersOrDRx = async (walletAddress) => {
  let balance1 = await seekersContractProvider.balanceOf(walletAddress);
  let balance2 = await drxContractProvider.balanceOf(walletAddress);
  return { hasSeekersOrDRx: balance1 + balance2 > 0 }
};

export const remainingMintsAllowed = async (walletAddress) => {
  let balance = await contractProvider.balanceOf(walletAddress);
  return MAX_NFTS_PER_WALLET - balance;
}

export const doDRxMint = async (amount) => {
  let tx = await contractSigner.mintDrugReceiptsClaim(amount, {
    value: PRICE_PER_NFT.mul(amount),
  });
  return tx;
};

export const doSeekersMint = async (amount) => {
  let tx = await contractSigner.mintSeekersClaim(amount, {
    value: PRICE_PER_NFT.mul(amount),
  });
  return tx;
};

export const doPublicMint = async (amount) => {
  let tx = await contractSigner.mintPublic(amount, {
    value: PRICE_PER_NFT.mul(amount),
  });
  return tx;
};

const sleep = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
};

export const signMessage = async (walletToAuthenticate, provider, context) => {
  let message = `This signature is to prove you are minting from the official minting website. It is free of charge.`;

  let signature;

  if (provider === "walletlink") {
    await sleep();
  }

  if (provider === "walletconnect" || provider === "walletlink") {
    signature = await context.library.provider.send("personal_sign", [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
      walletToAuthenticate.toLowerCase(),
    ]);
  } else {
    signature = await signer.signMessage(message);
  }

  return { signature };
};

export const saleStatus = async () => {
  return await fetch(`${API_URL}/claimstatus`, {
    method: "GET",
  }).then((response) => extractBody(response));
};
