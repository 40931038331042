import { Windmill } from "@windmill/react-ui";
import Mint from "./pages/Mint";
import ConnectWallet from "./pages/ConnectWallet";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useGlobalContext } from "./context/GlobalContext";

import styles from "./styles/home.module.scss";

function App() {
  const { isOnWhitelist } = useGlobalContext();
  const { active } = useWeb3React();
  const SALE_ENDED = true;

  if (SALE_ENDED) { 
    return window.location.replace("https://opensea.io/collection/drugreceipts-drx-travel-co") 
  }
  else {
    return (
      <div className={styles.container}>
        <Windmill>
          <BrowserRouter>
            <div className={styles.headerContainer}>
              <img
                className={styles.logo}
                src="/drlogostack.png"
                alt="DrugReceipts Logo"
              />
            </div>

            <div className={`${styles.main}`}>
              <div className={active ? styles.imgContainer : styles.imgContainer}>
                <img
                  className={styles.bossBear}
                  src="/hoodie.webp"
                  alt="Boss DR"
                />
              </div>

              <Routes>
                <Route path="/" element={<ConnectWallet />} />
                <Route path="/mint" element={<Mint />} />
              </Routes>
            </div>
            <Footer />
          </BrowserRouter>
        </Windmill>
      </div>
    );
  }
}

export default App;
